export default [
  {
    path: '/saida/',
    name: 'saida',
    title: 'saida',
    component: () => import('../view/SaidaView.vue'),
    redirect: { name: 'saida-cadastrar' },
    children: [
      {
        path: 'welcome',
        name: 'saida-listar',
        component: () => import('../view/SaidaWelcomeView.vue'),
        meta: {
          title: 'saida listar',
        },
      },
      {
        path: 'add',
        name: 'saida-cadastrar',
        meta: {
          title: 'saida cadastrar',
        },
        component: () => import('../view/SaidaFormView.vue'),
      },
      {
        path: ':id',
        name: 'saida-editar',
        meta: {
          title: 'saida editar',
        },
        component: () => import('../view/SaidaFormView.vue'),
      },
    ],
  },
];
