export default [
  {
    path: '/pessoa/',
    name: 'pessoa',
    title: 'pessoa',
    component: () => import('../view/PessoaView.vue'),
    redirect: { name: 'pessoa-listar' },
    children: [
      {
        path: 'welcome',
        name: 'pessoa-listar',
        component: () => import('../view/PessoaWelcomeView.vue'),
        meta: {
          title: 'pessoa listar',
        },
      },
      {
        path: 'add',
        name: 'pessoa-cadastrar',
        meta: {
          title: 'pessoa cadastrar',
        },
        component: () => import('../view/PessoaFormView.vue'),
      },
      {
        path: ':id',
        name: 'pessoa-editar',
        meta: {
          title: 'pessoa editar',
        },
        component: () => import('../view/PessoaFormView.vue'),
      },
    ],
  },
];
