export default [
  {
    path: '/centroCusto/',
    name: 'centroCusto',
    title: 'CentroCusto',
    component: () => import('../views/CentroCustoView.vue'),
    redirect: { name: 'centroCusto-listar' },
    children: [
      {
        path: 'welcome',
        name: 'centroCusto-listar',
        component: () => import('../views/CentroCustoWelcomeView.vue'),
      },
      {
        path: 'add',
        name: 'centroCusto-cadastrar',
        component: () => import('../views/CentroCustoFormView.vue'),
        meta: {
          title: 'Cadastrar centro de custo',
        },
      },
      {
        path: ':id',
        name: 'centroCusto-editar',
        meta: {
          title: 'Editar centro de custo',
        },
        component: () => import('../views/CentroCustoFormView.vue'),
      },
    ],
  },
];
