export default [
  {
    path: '/formaPagamento',
    name: 'formaPagamento',
    title: 'Forma de pagamento',
    component: () => import('../views/FormaPagamentoView.vue'),
    redirect: { name: 'formaPagamento-listar' },
    children: [
      {
        path: 'welcome',
        name: 'formaPagamento-listar',
        component: () => import('../views/FormaPagamentoWelcome.vue'),
        meta: {
          title: 'Forma de pagamento',
        },
      },
      {
        path: 'add',
        name: 'formaPagamento-cadastrar',
        component: () => import('../views/FormaPagamentoFormView.vue'),
        meta: {
          title: 'Cadastrar forma de pagamento',
        },
      },
      {
        path: ':id',
        name: 'formaPagamento-editar',
        component: () => import('../views/FormaPagamentoFormView.vue'),
        meta: {
          title: 'Editar forma de pagamento',
        },
      },
    ],
  },
];
