export default [
  {
    path: '/cfop/',
    name: 'cfop',
    title: 'CFOP',
    component: () => import('../views/CfopView.vue'),
    redirect: { name: 'cfop-listar' },
    children: [
      {
        path: 'welcome',
        name: 'cfop-listar',
        component: () => import('../views/CfopWelcomeView.vue'),
        meta: {
          title: 'CFOP',
        },
      },
      {
        path: 'add',
        name: 'cfop-cadastrar',
        component: () => import('../views/CfopFormView.vue'),
        meta: {
          title: 'Cadastrar CFOP',
        },
      },
      {
        path: ':id',
        name: 'cfop-editar',
        component: () => import('../views/CfopFormView.vue'),
        meta: {
          title: 'CFOP editar',
        },
      },
    ],
  },
];
