export default [
  {
    path: '/cidades/',
    name: 'cidades',
    title: 'Cidades',
    component: () => import('../views/CidadesView.vue'),
    redirect: { name: 'cidades-listar' },
    children: [
      {
        path: 'welcome',
        name: 'cidades-listar',
        component: () => import('../views/CidadesWelcomeView.vue'),
        meta: {
          title: 'Cidades',
        },
      },
      {
        path: 'add',
        name: 'cidades-cadastrar',
        component: () => import('../views/CidadesFormView.vue'),
        meta: {
          title: 'Cadastrar cidades',
        },
      },
      {
        path: ':id',
        name: 'cidades-editar',
        component: () => import('../views/CidadesFormView.vue'),
        meta: {
          title: 'Editar cidades',
        },
      },
    ],
  },
];
