export default [
  {
    path: '/setor/',
    name: 'setor',
    title: 'setor',
    component: () => import('../views/SetorView.vue'),
    redirect: { name: 'setor-listar' },
    children: [
      {
        path: 'welcome',
        name: 'setor-listar',
        component: () => import('../views/SetorWelcomeView.vue'),
        meta: {
          title: 'Setor',
        },
      },
      {
        path: 'add',
        name: 'setor-cadastrar',
        component: () => import('../views/SetorFormView.vue'),
        meta: {
          title: 'Cadastrar setor',
        },
      },
      {
        path: ':id',
        name: 'setor-editar',
        component: () => import('../views/SetorFormView.vue'),
        meta: {
          title: 'Editar setor',
        },
      },
    ],
  },
];
