export default [
  {
    path: '/contaBancaria/',
    name: 'contaBancaria',
    component: () => import('../views/ContaBancariaView.vue'),
    redirect: { name: 'contaBancaria-listar' },
    children: [
      {
        path: 'welcome',
        name: 'contaBancaria-listar',
        component: () => import('../views/ContaBancariaWelcomeView.vue'),
        meta: {
          title: 'Conta bancária',
        },
      },
      {
        path: 'add',
        name: 'contaBancaria-cadastrar',
        component: () => import('../views/ContaBancariaFormView.vue'),
        meta: {
          title: 'Cadastrar conta bancária',
        },
      },
      {
        path: ':id',
        name: 'contaBancaria-editar',
        component: () => import('../views/ContaBancariaFormView.vue'),
        meta: {
          title: 'Editar conta bancária',
        },
      },
    ],
  },
];
