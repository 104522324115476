export default [{
  path: '/operacao/',
  name: 'operacao',
  title: 'Operacao',
  component: () => import('../views/OperacaoView.vue'),
  redirect: { name: 'operacao-listar' },
  children: [
    {
      path: 'welcome',
      name: 'operacao-listar',
      component: () => import('../views/OperacaoWelcomeView.vue'),
    },
    {
      path: 'add',
      name: 'operacao-cadastrar',
      component: () => import('../views/OperacaoFormView.vue'),
      meta: {
        title: 'Cadastrar operação',
      },
    },
    {
      path: ':id',
      name: 'operacao-editar',
      meta: {
        title: 'Editar operação',
      },
      component: () => import('../views/OperacaoFormView.vue'),
    },
  ],
}];
