import { enumUtil } from '@/utils';

const TipoAdicionalEnumerator = Object.freeze({
  ComSem: new enumUtil.EnumBase(0, 'Com/sem'),
  Com: new enumUtil.EnumBase(1, 'Com'),
  Sem: new enumUtil.EnumBase(2, 'Sem'),
});

const ComportamentoTelaAdicionaisEnumerator = Object.freeze({
  Produto: new enumUtil.EnumBase(1, 'Produto'),
  ClassificacaoMercadologica: new enumUtil.EnumBase(2, 'Classificação mercadológica'),
});

export { TipoAdicionalEnumerator, ComportamentoTelaAdicionaisEnumerator };
