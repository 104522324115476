export default [
  {
    path: '/banco/',
    name: 'banco',
    title: 'banco',
    component: () => import('../views/BancoView.vue'),
    redirect: { name: 'banco-listar' },
    children: [
      {
        path: 'welcome',
        name: 'banco-listar',
        component: () => import('../views/BancoWelcomeView.vue'),
        meta: {
          title: 'Banco',
        },
      },
      {
        path: 'add',
        name: 'banco-cadastrar',
        component: () => import('../views/BancoFormView.vue'),
        meta: {
          title: 'Cadastrar banco',
        },
      },
      {
        path: ':id',
        name: 'banco-editar',
        meta: {
          title: 'Editar banco',
        },
        component: () => import('../views/BancoFormView.vue'),
      },
    ],
  },
];
