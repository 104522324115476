export default [
  {
    path: '/unidade/',
    name: 'unidadeVenda',
    component: () => import('../views/UnidadeView.vue'),
    redirect: { name: 'unidade-listar' },
    children: [
      {
        path: 'welcome',
        name: 'unidade-listar',
        meta: {
          title: 'Unidade',
        },
        component: () => import('../views/UnidadeWelcomeView.vue'),
      },
      {
        path: 'add',
        name: 'unidade-cadastrar',
        meta: {
          title: 'Unidade cadastrar',
        },
        component: () => import('../views/UnidadeFormView.vue'),
      },
      {
        path: ':id',
        name: 'unidade-editar',
        meta: {
          title: 'Unidade editar',
        },
        component: () => import('../views/UnidadeFormView.vue'),
      },
    ],
  },
];
