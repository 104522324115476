import recebiveisRouter from '../recebiveis/router';
import contaPagarRouter from '../contasPagar/router';

const router = [
  {
    path: '/financeiroConta/',
    name: 'financeiroConta',
    title: 'financeiroConta',
    component: () => import('../views/FinanceiroContaView.vue'),
    redirect: { name: 'financeiroConta-listar' },
    children: [...recebiveisRouter, ...contaPagarRouter],
  },
];

export default router;
