export default [
  {
    path: '/ordemServico/',
    name: 'ordemServico',
    title: 'ordemServico',
    component: () => import('../views/OrdemServicoView.vue'),
    redirect: { name: 'ordemServico-listar' },
    children: [
      {
        path: 'add',
        name: 'ordemServico-cadastrar',
        component: () => import('../views/OrdemServicoFormView.vue'),
        meta: {
          title: 'Cadastrar ordem de serviço',
        },
      },
      {
        path: 'welcome',
        name: 'ordemServico-listar',
        component: () => import('../views/OrdemServicoWelcomeView.vue'),
        meta: {
          title: 'Ordem de serviço',
        },
      },
      {
        path: ':id',
        name: 'ordemServico-editar',
        component: () => import('../views/OrdemServicoFormView.vue'),
        meta: {
          title: 'Editar ordem de serviço',
        },
      },
    ],
  },
];
