function formatDate(value, typeFormat = 'short') {
  const date = value == null ? new Date() : new Date(value);
  const formatter = new Intl.DateTimeFormat('pt-Br', { dateStyle: typeFormat });

  return formatter.format(date);
}

function formatTime(value, typeFormat = 'short') {
  const date = value == null ? new Date() : new Date(value);
  const formatter = new Intl.DateTimeFormat('pt-Br', { timeStyle: typeFormat });

  return formatter.format(date);
}

// O método getDay() retorna o dia da semana de 0-6, sendo Domingo = 0 e Sábado = 6.
function getWeekDay(date) {
  return date.getDay() + 1;
}

const getStringDateDefault = '1900-01-01T00:00:00.000Z';
const getStringDateDefaultLocate = '01/01/1900';
const getDateDefault = '1900-01-01T00:00:00';

const todayStart = new Date(new Date().setHours(0, 0, 0, 999));
const todayEnd = new Date(new Date().setHours(23, 59, 59, 999));

const tomorrowStart = new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() + 1));
const tomorrowEnd = new Date(new Date(new Date().setHours(23, 59, 59, 999)).setDate(new Date().getDate() + 1));

const monthStart = new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 0));
const monthEnd = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(23, 59, 59, 999));

const nextMonthStart = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).setHours(0, 0, 0, 0));
const nextMonthEnd = new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0).setHours(23, 59, 59, 999));

function toStringDateTime(date) {
  const datePart = [date.getDate(), date.getMonth() + 1, date.getFullYear()].map((n, i) => n.toString().padStart(i === 2 ? 4 : 2, '0')).join('/');
  const timePart = [date.getHours(), date.getMinutes(), date.getSeconds()].map((n) => n.toString().padStart(2, '0')).join(':');
  return `${datePart} ${timePart}`;
}

export default {
  formatDate,
  getStringDateDefault,
  getStringDateDefaultLocate,
  todayStart,
  todayEnd,
  tomorrowStart,
  tomorrowEnd,
  monthStart,
  monthEnd,
  nextMonthStart,
  nextMonthEnd,
  formatTime,
  getDateDefault,
  getWeekDay,
  toStringDateTime,
};
