export default [
  {
    path: '/classificacaoMercadologica/',
    name: 'classificacaoMercadologica',
    component: () => import('../views/ClassificacaoMercadologicaView.vue'),
    redirect: { name: 'classificacaoMercadologica-listar' },
    children: [
      {
        path: 'welcome',
        name: 'classificacaoMercadologica-listar',
        component: () => import('../views/ClassificacaoMercadologicaWelcomeView.vue'),
        meta: {
          title: 'Classificação mercadológica',
        },
      },
      {
        path: 'add',
        name: 'classificacaoMercadologica-cadastrar',
        component: () => import('../views/ClassificacaoMercadologicaFormView.vue'),
        meta: {
          title: 'Cadastrar classificação mercadológica',
        },
      },
      {
        path: ':id',
        name: 'classificacaoMercadologica-editar',
        component: () => import('../views/ClassificacaoMercadologicaFormView.vue'),
        meta: {
          title: 'Editar classificação mercadológica',
        },
      },
    ],
  },
];
