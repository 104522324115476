export default [
  {
    path: '/pais/',
    name: 'pais',
    title: 'Pais',
    component: () => import('../views/PaisView.vue'),
    redirect: { name: 'pais-listar' },
    children: [
      {
        path: 'welcome',
        name: 'pais-listar',
        component: () => import('../views/PaisWelcomeView.vue'),
        meta: {
          title: 'Países',
        },
      },
      {
        path: 'add',
        name: 'pais-cadastrar',
        component: () => import('../views/PaisFormView.vue'),
        meta: {
          title: 'Cadastrar países',
        },
      },
      {
        path: ':id',
        name: 'pais-editar',
        component: () => import('../views/PaisFormView.vue'),
        meta: {
          title: 'Editar países',
        },
      },
    ],
  },
];
