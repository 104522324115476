export default [
  {
    path: '/cargaTributaria/',
    name: 'cargaTributaria',
    component: () => import('../views/CargaTributariaView.vue'),
    redirect: { name: 'cargaTributaria-listar' },
    children: [
      {
        path: 'welcome',
        name: 'cargaTributaria-listar',
        component: () => import('../views/CargaTributariaWelcomeView.vue'),
      },
      {
        path: 'add',
        name: 'cargaTributaria-cadastrar',
        component: () => import('../views/CargaTributariaFormView.vue'),
        meta: {
          title: 'Cadastrar carga tributária',
        },
      },
      {
        path: ':id',
        name: 'cargaTributaria-editar',
        component: () => import('../views/CargaTributariaFormView.vue'),
        meta: {
          title: 'Editar carga tributária',
        },
      },
    ],
  },
];
