import { ComportamentoTelaAdicionaisEnumerator } from '@/enums/outros/adicional';

export default [{
  path: '/adicional/',
  name: 'adicional',
  title: 'Adicional',
  component: () => import('../views/AdicionalView.vue'),
  redirect: { name: 'adicional-listar' },
  children: [
    {
      path: 'welcome/produto',
      name: 'adicionalProduto-listar',
      component: () => import('../views/AdicionalWelcomeView.vue'),
      meta: {
        title: 'Adicionais de produto',
      },
      props: {
        tipoTelaAdicional: ComportamentoTelaAdicionaisEnumerator.Produto.valueEnum,
      },
    },
    {
      path: 'welcome/classificacaoMercadologica',
      name: 'adicionalClassificacaoMercadologica-listar',
      component: () => import('../views/AdicionalWelcomeView.vue'),
      meta: {
        title: 'Adicionais de classificação mercadológica',
      },
      props: {
        tipoTelaAdicional: ComportamentoTelaAdicionaisEnumerator.ClassificacaoMercadologica.valueEnum,
      },
    },
    {
      path: 'add/produto',
      name: 'adicionalProduto-cadastrar',
      component: () => import('../views/AdicionalFormView.vue'),
      meta: {
        title: 'Cadastrar adicionais de produto',
      },
      props: {
        tipoTelaAdicional: ComportamentoTelaAdicionaisEnumerator.Produto.valueEnum,
      },
    },
    {
      path: 'add/classificacaoMercadologica',
      name: 'adicionalClassificacaoMercadologica-cadastrar',
      component: () => import('../views/AdicionalFormView.vue'),
      meta: {
        title: 'Cadastrar adicionais de classificação mercadológica',
      },
      props: {
        tipoTelaAdicional: ComportamentoTelaAdicionaisEnumerator.ClassificacaoMercadologica.valueEnum,
      },
    },
    {
      path: 'produto/:id',
      name: 'adicionalProduto-editar',
      component: () => import('../views/AdicionalFormView.vue'),
      meta: {
        title: 'Editar produto',
      },
      props: {
        tipoTelaAdicional: ComportamentoTelaAdicionaisEnumerator.Produto.valueEnum,
      },
    },
    {
      path: 'classificacaoMercadologica/:id',
      name: 'adicionalClassificacaoMercadologica-editar',
      component: () => import('../views/AdicionalFormView.vue'),
      meta: {
        title: 'Editar classificação mercadológica',
      },
      props: {
        tipoTelaAdicional: ComportamentoTelaAdicionaisEnumerator.ClassificacaoMercadologica.valueEnum,
      },
    },
  ],
}];
