export default [
  {
    path: '/produtoComposicao/',
    name: 'produtoComposicao',
    component: () => import('../views/ProdutoComposicaoView.vue'),
    title: 'Produto composição',
    redirect: { name: 'produtoComposicao-listar' },
    children: [
      {
        path: 'welcome',
        name: 'produtoComposicao-listar',
        component: () => import('../views/ProdutoComposicaoWelcomeView.vue'),
        meta: {
          title: 'Produto composição',
        },
      },
      {
        path: 'add',
        name: 'produtoComposicao-cadastrar',
        component: () => import('../views/ProducaoFormView.vue'),
        meta: {
          title: 'Cadastrar produção',
        },
      },
      {
        path: ':id',
        name: 'produtoComposicao-editar',
        component: () => import('../views/ProducaoFormView.vue'),
        meta: {
          title: 'Editar produção',
        },
      },
    ],
  },
];
