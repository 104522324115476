export default [
  {
    path: '/planoConta3/',
    name: 'planoConta3',
    title: 'planoConta3',
    component: () => import('../views/PlanoConta3View.vue'),
    redirect: { name: 'planoConta3-listar' },
    children: [
      {
        path: 'welcome',
        name: 'planoConta3-listar',
        meta: {
          title: 'Plano de conta 3',
        },
        component: () => import('../views/PlanoConta3WelcomeView.vue'),
      },
      {
        path: 'add',
        name: 'planoConta3-cadastrar',
        meta: {
          title: 'Plano de conta 3 - cadastrar',
        },
        component: () => import('../views/PlanoConta3FormView.vue'),
      },
      {
        path: ':id',
        name: 'planoConta3-editar',
        meta: {
          title: 'Plano de conta 3 - editar',
        },
        component: () => import('../views/PlanoConta3FormView.vue'),
      },
    ],
  },
];
