export default [
  {
    path: '/planoConta1',
    name: 'planoConta1',
    title: 'planoConta1',
    component: () => import('../views/PlanoConta1View.vue'),
    redirect: { name: 'planoConta1-listar' },
    children: [
      {
        path: 'welcome',
        name: 'planoConta1-listar',
        meta: {
          title: 'Plano de conta 1',
        },
        component: () => import('../views/PlanoConta1WelcomeView.vue'),
      },
      {
        path: 'add',
        name: 'planoConta1-cadastrar',
        meta: {
          title: 'Plano de conta 1 - cadastrar',
        },
        component: () => import('../views/PlanoConta1FormView.vue'),
      },
      {
        path: ':id',
        name: 'planoConta1-editar',
        meta: {
          title: 'Plano de conta 1 - editar',
        },
        component: () => import('../views/PlanoConta1FormView.vue'),
      },
    ],
  },
];
