function formatDecimal(value, qtdeDecimal = 0) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    minimumFractionDigits: qtdeDecimal,
    maximumFractionDigits: qtdeDecimal,
  });

  return formatter.format(value);
}

export default { formatDecimal };
