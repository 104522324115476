export default [
  {
    path: '/funcionario/',
    name: 'funcionario',
    title: 'funcionario',
    component: () => import('../views/FuncionarioView.vue'),
    redirect: { name: 'funcionario-listar' },
    children: [
      {
        path: 'welcome',
        name: 'funcionario-listar',
        component: () => import('../views/FuncionarioWelcomeView.vue'),
      },
      {
        path: 'add',
        name: 'funcionario-cadastrar',
        component: () => import('../views/FuncionarioFormView.vue'),
        meta: {
          title: 'cadastro funcionario',
        },
      },
      {
        path: ':id',
        name: 'funcionario editar',
        meta: {
          title: 'Funcionario editar',
        },
        component: () => import('../views/FuncionarioFormView.vue'),
      },
    ],
  },
];
