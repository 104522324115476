export default [
  {
    path: '/motivoVendaPerdida/',
    name: 'motivoVendaPerdida',
    title: 'motivoVendaPerdida',
    component: () => import('../views/MotivoVendaPerdidaView.vue'),
    redirect: { name: 'motivoVendaPerdida-listar' },
    children: [
      {
        path: 'add',
        name: 'motivoVendaPerdida-cadastrar',
        component: () => import('../views/MotivoVendaPerdidaFormView.vue'),
        meta: {
          title: 'Cadastrar motivo de venda perdida',
        },
      },
      {
        path: 'welcome',
        name: 'motivoVendaPerdida-listar',
        component: () => import('../views/MotivoVendaPerdidaWelcomeView.vue'),
        meta: {
          title: 'Motivo venda perdida',
        },
      },
      {
        path: ':id',
        name: 'motivoVendaPerdida-editar',
        component: () => import('../views/MotivoVendaPerdidaFormView.vue'),
        meta: {
          title: 'Editar motivo de venda perdida',
        },
      },
    ],
  },
];
