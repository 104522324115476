export default [{
  path: '/areaImpressao/',
  name: 'areaImpressao',
  title: 'AreaImpressao',
  component: () => import('../views/AreaImpressaoView.vue'),
  redirect: { name: 'areaImpressao-listar' },
  children: [
    {
      path: 'welcome',
      name: 'areaImpressao-listar',
      component: () => import('../views/AreaImpressaoWelcomeView.vue'),
      meta: {
        title: 'Área de impressão',
      },
    },
    {
      path: 'add',
      name: 'areaImpressao-cadastrar',
      component: () => import('../views/AreaImpressaoFormView.vue'),
      meta: {
        title: 'Cadastrar área de impressão',
      },
    },
    {
      path: ':id',
      name: 'areaImpressao-editar',
      component: () => import('../views/AreaImpressaoFormView.vue'),
      meta: {
        title: 'Editar área de impressão',
      },
    },
  ],
}];
