export default [
  {
    path: '/produto/',
    name: 'produto',
    title: 'produto',
    component: () => import('../views/ProdutoView.vue'),
    redirect: { name: 'produto-listar' },
    children: [
      {
        path: 'welcome',
        name: 'produto-listar',
        meta: {
          title: 'Produto',
        },
        component: () => import('../views/ProdutoWelcomeView.vue'),
      },
      {
        path: 'add',
        name: 'produto-cadastrar',
        meta: {
          title: 'Produto cadastrar',
        },
        component: () => import('../views/ProdutoFormView.vue'),
      },
      {
        path: ':id',
        name: 'produto editar',
        meta: {
          title: 'produto editar',
        },
        component: () => import('../views/ProdutoFormView.vue'),
      },
    ],
  },
];
