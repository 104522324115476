export default [{
  path: '/administradoraCartao/',
  name: 'administradoraCartao',
  title: 'AdministradoraCartao',
  component: () => import('../views/AdministradoraCartaoView.vue'),
  redirect: { name: 'administradoraCartao-listar' },
  children: [
    {
      path: 'welcome',
      name: 'administradoraCartao-listar',
      component: () => import('../views/AdministradoraCartaoWelcomeView.vue'),
      meta: {
        title: 'Administradora de cartão',
      },
    },
    {
      path: 'add',
      name: 'administradoraCartao-cadastrar',
      component: () => import('../views/AdministradoraCartaoFormView.vue'),
      meta: {
        title: 'Cadastrar administradora de cartão',
      },
    },
    {
      path: ':id',
      name: 'administradoraCartao-editar',
      meta: {
        title: 'Editar administradora de cartão',
      },
      component: () => import('../views/AdministradoraCartaoFormView.vue'),
    },
  ],
}];
