export default [
  {
    path: '/filial/',
    name: 'filial',
    title: 'filial',
    component: () => import('../views/FilialView.vue'),
    redirect: { name: 'filial-cadastrar' },
    children: [
      {
        path: 'add',
        name: 'filial-cadastrar',
        component: () => import('../views/FilialFormView.vue'),
        meta: {
          title: 'configurar tela',
        },
      },
      {
        path: 'selecionar',
        name: 'FilialSelecionar',
        component: () => import('../views/FilialSelecionarView.vue'),
        meta: {
          layout: 'EmptyLayout',
        },
      },
    ],
  },
];
