export default [
  {
    path: '/familia/',
    name: 'familia',
    title: 'Família',
    component: () => import('../views/FamiliaView.vue'),
    redirect: { name: 'familia-listar' },
    children: [
      {
        path: 'welcome',
        name: 'familia-listar',
        component: () => import('../views/FamiliaWelcomeView.vue'),
      },
      {
        path: 'add',
        name: 'familia-cadastrar',
        component: () => import('../views/FamiliaFormView.vue'),
        meta: {
          title: 'Cadastrar família',
        },
      },
      {
        path: ':id',
        name: 'familia editar',
        meta: {
          title: 'Editar família',
        },
        component: () => import('../views/FamiliaFormView.vue'),
      },
    ],
  },
];
