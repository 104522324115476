export default [{
  path: '/bandeiraCartao/',
  name: 'bandeiraCartao',
  title: 'BandeiraCartao',
  component: () => import('../views/BandeiraCartaoView.vue'),
  redirect: { name: 'bandeiraCartao-listar' },
  children: [
    {
      path: 'welcome',
      name: 'bandeiraCartao-listar',
      component: () => import('../views/BandeiraCartaoWelcomeView.vue'),
    },
    {
      path: 'add',
      name: 'bandeiraCartao-cadastrar',
      component: () => import('../views/BandeiraCartaoFormView.vue'),
      meta: {
        title: 'Cadastrar bandeira de cartão',
      },
    },
    {
      path: ':id',
      name: 'bandeiraCartao-editar',
      meta: {
        title: 'Editar bandeira de cartão',
      },
      component: () => import('../views/BandeiraCartaoFormView.vue'),
    },
  ],
}];
