export default [
  {
    path: '/marca/',
    name: 'marca',
    component: () => import('../views/MarcaView.vue'),
    redirect: { name: 'marca-listar' },
    children: [
      {
        path: 'welcome',
        name: 'marca-listar',
        meta: {
          title: 'Marca',
        },
        component: () => import('../views/MarcaWelcomeView.vue'),
      },
      {
        path: 'add',
        name: 'marca-cadastrar',
        meta: {
          title: 'Marca cadastrar',
        },
        component: () => import('../views/MarcaFormView.vue'),
      },
      {
        path: ':id',
        name: 'marca-editar',
        meta: {
          title: 'Marca editar',
        },
        component: () => import('../views/MarcaFormView.vue'),
      },
    ],
  },
];
