import unidadeRouters from '@/modules/estoque/unidade/router';
import cestRouters from '@/modules/estoque/cest/router';
import produtoRouters from '@/modules/estoque/produto/router';
import sobreRouters from '@/modules/configuracoes/version/router';
import cliForRouters from '@/modules/crm/pessoa/router';
import marcaRouters from '@/modules/estoque/marca/router';
import saidaRouters from '@/modules/comercial/saidas/router';
import configurarRouters from '@/modules/configuracoes/configuracao/router';
import filialRouters from '@/modules/configuracoes/filial/router';
import classeRouters from '@/modules/estoque/classe/router';
import subClasseRouters from '@/modules/estoque/subclasse/router';
import familiaRouters from '@/modules/estoque/familia/router';
import grupoRouters from '@/modules/estoque/grupo/router';
import { createRouter, createWebHistory } from 'vue-router';
import vendaPerdidaRouters from '@/modules/comercial/vendaPerdida/router';
import motivoVendaPerdidaRouters from '@/modules/comercial/motivoVendaPerdida/router';
import caixaRouters from '@/modules/financeiro/caixa/router';
import funcionarioRouters from '@/modules/configuracoes/funcionario/router';
import permissaoGrupoRouters from '@/modules/configuracoes/permissaoGrupo/router';
import resumoVendasRouters from '@/modules/comercial/resumoVendas/router';
import bandeiraCartaoRouters from '@/modules/financeiro/bandeiraCartao/router';
import planoConta1Routers from '@/modules/financeiro/planoContas1/router';
import planoConta2Routers from '@/modules/financeiro/planoContas2/router';
import planoConta3Routers from '@/modules/financeiro/planoContas3/router';
import bancoRouters from '@/modules/financeiro/banco/router';
import centroCustoRouters from '@/modules/financeiro/centroCusto/router';
import financeiroContaRouters from '@/modules/financeiro/financeiroConta/router';
import classeImpostoRouters from '@/modules/estoque/classeImposto/router';
import classeImpostoOperacaoRouters from '@/modules/estoque/classeImpostoOperacao/router';
import tabelaPrecoRouters from '@/modules/estoque/tabelaPreco/router';
import cargaTributariaRouters from '@/modules/estoque/cargaTributaria/router';
import setorRouters from '@/modules/outros/setor/router';
import classificacaoMercadologicaRouters from '@/modules/estoque/classificacaoMercadologica/router';
import contaBancariaRouters from '@/modules/financeiro/contaBancaria/router';
import formaPagamentoRouter from '@/modules/financeiro/formaPagamento/router';
import operacaoRouters from '@/modules/configuracoes/operacao/router';
import administradoraCartaoRouters from '@/modules/financeiro/administradoraCartao/router';
import partnerRouters from '@/modules/configuracoes/partner/router';
import cfopRouters from '@/modules/estoque/cfop/router';
import cidadesRouters from '@/modules/crm/cidades/router';
import nfeWebService from '@/modules/configuracoes/nfeWebService/router';
import ncmRouters from '@/modules/estoque/ncm/router';
import produtoComposicaoRouter from '@/modules/estoque/produtoComposicao/router';
import veiculoRouters from '@/modules/comercial/veiculo/router';
import areaImpressaoRouters from '@/modules/outros/areaImpressao/router';
import mesaRouters from '@/modules/outros/mesa/router';
import paisRouters from '@/modules/crm/pais/router';
import OrdemServicoRouter from '@/modules/comercial/ordemServico/router';
import OsTipoRouter from '@/modules/comercial/osTipo/router';
import OsSituacaoRouter from '@/modules/comercial/osSituacao/router';
import DadosOsConfigRouter from '@/modules/comercial/dadosOsConfig/router';
import dfeRouters from '@/modules/comercial/dfe/router';
import icmsUfsRouters from '@/modules/estoque/icms/router';
import favoritoProdutoRouters from '@/modules/outros/favoritoProduto/router';
import outrosRouters from '@/router/outrosRouters/index';

const baseRoutes = [
  {
    path: '/',
    name: 'home',
    title: 'Home',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/error/',
    name: 'error',
    component: () => import('../views/ErrorView.vue'),
    meta: {
      layout: 'EmptyLayout',
    },
  },
  {
    path: '/accessdenied/',
    name: 'accessdenied',
    component: () => import('../views/AccessDenied.vue'),
    meta: {
      layout: 'EmptyLayout',
    },
  },
  {
    path: '/keys/',
    name: 'keys',
    component: () => import('../modules/configuracoes/keys/views/SerialFormView.vue'),
    meta: {
      layout: 'EmptyLayout',
    },
  },
  {
    path: '/keys/liberacaoAviso',
    name: 'liberacaoAviso',
    component: () => import('../modules/configuracoes/keys/views/LiberacaoAvisoView.vue'),
    meta: {
      layout: 'EmptyLayout',
    },
  },
  {
    path: '/keys/pagamentoLicenca',
    name: 'pagamentoLicenca',
    component: () => import('../modules/configuracoes/keys/views/PagamentoLicencaView.vue'),
    meta: {
      layout: 'EmptyLayout',
    },
  },
  {
    path: '/keys/pagamentoLicencaPix',
    name: 'pagamentoLicencaPix',
    component: () => import('../modules/configuracoes/keys/views/PagamentoLicencaPixView.vue'),
    meta: {
      layout: 'EmptyLayout',
    },
  },
  {
    path: '/keys/pagamentoLicencaBoleto',
    name: 'pagamentoLicencaBoleto',
    component: () => import('../modules/configuracoes/keys/views/PagamentoLicencaBoletoView.vue'),
    meta: {
      layout: 'EmptyLayout',
    },
  },
];

const modulesRoute = [
  ...cestRouters,
  ...unidadeRouters,
  ...produtoRouters,
  ...sobreRouters,
  ...cliForRouters,
  ...marcaRouters,
  ...saidaRouters,
  ...configurarRouters,
  ...filialRouters,
  ...classeRouters,
  ...subClasseRouters,
  ...grupoRouters,
  ...familiaRouters,
  ...vendaPerdidaRouters,
  ...motivoVendaPerdidaRouters,
  ...caixaRouters,
  ...funcionarioRouters,
  ...permissaoGrupoRouters,
  ...resumoVendasRouters,
  ...bandeiraCartaoRouters,
  ...planoConta1Routers,
  ...planoConta2Routers,
  ...planoConta3Routers,
  ...bancoRouters,
  ...centroCustoRouters,
  ...financeiroContaRouters,
  ...classeImpostoRouters,
  ...classeImpostoOperacaoRouters,
  ...tabelaPrecoRouters,
  ...cargaTributariaRouters,
  ...setorRouters,
  ...classificacaoMercadologicaRouters,
  ...contaBancariaRouters,
  ...formaPagamentoRouter,
  ...operacaoRouters,
  ...administradoraCartaoRouters,
  ...partnerRouters,
  ...cfopRouters,
  ...cidadesRouters,
  ...nfeWebService,
  ...ncmRouters,
  ...produtoComposicaoRouter,
  ...veiculoRouters,
  ...areaImpressaoRouters,
  ...paisRouters,
  ...mesaRouters,
  ...OrdemServicoRouter,
  ...OsTipoRouter,
  ...OsSituacaoRouter,
  ...DadosOsConfigRouter,
  ...dfeRouters,
  ...icmsUfsRouters,
  ...favoritoProdutoRouters,
];
const routes = baseRoutes.concat(modulesRoute).concat(outrosRouters);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title || to.name} - ${process.env.VUE_APP_TITLE}`;
  if (to.name === 'liberacaoAviso' && !to.params.urlRouter) {
    next({ name: 'liberacaoAviso', params: { urlRouter: from.name } });
  } else {
    next();
  }
});

export default router;
