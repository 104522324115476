export default [
  {
    path: '/nfeWebService/',
    name: 'nfeWebService',
    title: 'NfeWebService',
    component: () => import('../views/NfeWebServiceView.vue'),
    redirect: { name: 'webService-listar' },
    children: [
      {
        path: 'add',
        name: 'nfeWebService-cadastrar',
        meta: {
          title: 'WebService',
        },
        component: () => import('../views/NfeWebServiceFormView.vue'),
      },
      {
        path: ':id',
        name: 'nfeWebService-editar',
        meta: {
          title: 'WebService editar',
        },
        component: () => import('../views/NfeWebServiceFormView.vue'),
      },
    ],
  },
];
