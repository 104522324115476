export default [
  {
    path: '/vendaPerdida/',
    name: 'vendaPerdida',
    title: 'VendaPerdida',
    component: () => import('../views/VendaPerdidaView.vue'),
    redirect: { name: 'vendaPerdida-listar' },
    children: [
      {
        path: 'welcome',
        name: 'vendaPerdida-listar',
        component: () => import('../views/VendaPerdidaWelcomeView.vue'),
        meta: {
          title: 'Venda perdida',
        },
      },
      {
        path: 'add',
        name: 'vendaPerdida-cadastrar',
        component: () => import('../views/VendaPerdidaFormView.vue'),
        meta: {
          title: 'Lançar venda perdida',
        },
      },
      {
        path: ':id',
        name: 'vendaPerdida editar',
        component: () => import('../views/VendaPerdidaFormView.vue'),
        meta: {
          title: 'Editar venda perdida',
        },
      },
      {
        path: 'relatorio/resumo-venda-perdida',
        name: 'vendaPerdida-Relatorio',
        component: () => import('../views/VendaPerdidaRelatorioView.vue'),
        meta: {
          title: 'Relatório',
        },
      },
    ],
  },
];
