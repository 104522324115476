export default [
  {
    path: '/cest/',
    name: 'cest',
    title: 'CEST',
    component: () => import('../views/CestView.vue'),
    redirect: { name: 'cest-listar' },
    children: [
      {
        path: 'welcome',
        name: 'cest-listar',
        component: () => import('../views/CestWelcomeView.vue'),
        meta: {
          title: 'CEST',
        },
      },
      {
        path: 'add',
        name: 'cest-cadastrar',
        component: () => import('../views/CestFormView.vue'),
        meta: {
          title: 'Cadastrar CEST',
        },
      },
      {
        path: ':id',
        name: 'cest editar',
        meta: {
          title: 'CEST editar',
        },
        component: () => import('../views/CestFormView.vue'),
      },
    ],
  },
];
