export default [{
  path: '/mesa/',
  name: 'mesa',
  title: 'Mesa',
  component: () => import('../views/MesaView.vue'),
  redirect: { name: 'mesa-listar' },
  children: [
    {
      path: 'welcome',
      name: 'mesa-listar',
      component: () => import('../views/MesaWelcomeView.vue'),
      meta: {
        title: 'Mesa',
      },
    },
    {
      path: 'add',
      name: 'mesa-cadastrar',
      component: () => import('../views/MesaFormView.vue'),
      meta: {
        title: 'Cadastrar mesa',
      },
    },
    {
      path: ':id',
      name: 'mesa-editar',
      component: () => import('../views/MesaFormView.vue'),
      meta: {
        title: 'Editar mesa',
      },
    },
    {
      path: 'lote',
      name: 'lote-cadastrar',
      component: () => import('../views/CadastrarLoteFormView.vue'),
      meta: {
        title: 'Cadastrar lote',
      },
    },
  ],
}];
