export default [
  {
    path: 'recebiveis/welcome',
    name: 'financeiroConta-listar',
    component: () => import('../views/RecebiveisWelcome.vue'),
    meta: {
      title: 'Buscar recebíveis',
    },
  },
  {
    path: 'recebiveis/add',
    name: 'financeiroConta-cadastrar',
    component: () => import('../views/RecebiveisFormView.vue'),
    meta: {
      title: 'Cadastrar recebíveis',
    },
  },
  {
    path: 'recebiveis/:id',
    name: 'financeiroConta-editar',
    component: () => import('../views/RecebiveisFormView.vue'),
    meta: {
      title: 'Editar recebíveis',
    },
  },
];
