export default [
  {
    path: '/classeImposto/',
    name: 'classeImposto',
    title: 'Classe imposto',
    component: () => import('../views/ClasseImpostoView.vue'),
    redirect: { name: 'classeImposto-listar' },
    children: [
      {
        path: 'welcome',
        name: 'classeImposto-listar',
        component: () => import('../views/ClasseImpostoWelcomeView.vue'),
      },
      {
        path: 'add',
        name: 'classeImposto-cadastrar',
        component: () => import('../views/ClasseImpostoFormView.vue'),
        meta: {
          title: 'Classe imposto cadastrar',
        },
      },
      {
        path: ':id',
        name: 'classeImposto-editar',
        component: () => import('../views/ClasseImpostoFormView.vue'),
        meta: {
          title: 'Classe imposto editar',
        },
      },
    ],
  },
];
