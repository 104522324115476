export default [
  {
    path: '/grupo/',
    name: 'grupo',
    title: 'Grupo',
    component: () => import('../views/GrupoView.vue'),
    redirect: { name: 'grupo-listar' },
    children: [
      {
        path: 'welcome',
        name: 'grupo-listar',
        component: () => import('../views/GrupoWelcomeView.vue'),
      },
      {
        path: 'add',
        name: 'grupo-cadastrar',
        component: () => import('../views/GrupoFormView.vue'),
        meta: {
          title: 'Cadastrar Grupo',
        },
      },
      {
        path: ':id',
        name: 'grupo-editar',
        meta: {
          title: 'Editar Grupo',
        },
        component: () => import('../views/GrupoFormView.vue'),
      },
    ],
  },
];
