export default [
  {
    path: '/dadosOsConfig/',
    name: 'dadosOsConfig',
    component: () => import('../views/DadosOsConfigView.vue'),
    redirect: { name: 'dadosOsConfig-cadastrar' },
    children: [
      {
        path: 'add',
        name: 'dadosOsConfig-cadastrar',
        component: () => import('../views/DadosOsConfigFormView.vue'),
        meta: {
          title: 'Configurar campos personalizados da OS',
        },
      },
    ],
  },
];
