function formatCurrency(value, qtdeDecimal = 2) {
  const formatter = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    minimumFractionDigits: qtdeDecimal === 0 ? 2 : qtdeDecimal,
  });

  return formatter.format(value);
}

export default { formatCurrency };
