export default [
  {
    path: '/partner/',
    name: 'partner',
    title: 'partner',
    component: () => import('../views/PartnerView.vue'),
    children: [
      {
        path: 'add',
        name: 'partner-cadastrar',
        component: () => import('../views/PartnerFormView.vue'),
        meta: {
          title: 'Partner listar',
        },
      },
      {
        path: ':id',
        name: 'partner-editar',
        component: () => import('../views/PartnerFormView.vue'),
        meta: {
          title: 'Partner editar',
        },
      },
    ],
  },
];
