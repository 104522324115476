export default [
  {
    path: '/configurar/',
    name: 'configurar',
    title: 'configurar',
    component: () => import('../view/ConfiguracaoView.vue'),
    redirect: { name: 'configurar-tela' },
    children: [
      {
        path: 'tela/saida',
        name: 'configurar-tela-saida',
        component: () => import('../etradeWebConfig/view/EtradeWebSaidaConfig.vue'),
        meta: {
          title: 'configurar tela',
        },
      },
      {
        path: 'tela/ordemServico',
        name: 'configurar-tela-ordemServico',
        component: () => import('../etradeWebOrdemServicoConfig/views/ETradeWebOrdemServicoConfigView.vue'),
        meta: {
          title: 'Configurar tela de ordem de serviço',
        },
      },
    ],
  },
];
