export default [
  {
    path: '/planoConta2/',
    name: 'planoConta2',
    title: 'planoConta2',
    component: () => import('../views/PlanoConta2View.vue'),
    redirect: { name: 'planoConta2-listar' },
    children: [
      {
        path: 'welcome',
        name: 'planoConta2-listar',
        meta: {
          title: 'Plano de conta 2',
        },
        component: () => import('../views/PlanoConta2WelcomeView.vue'),
      },
      {
        path: 'add',
        name: 'planoConta2-cadastrar',
        meta: {
          title: 'Plano de conta 2 - cadastrar',
        },
        component: () => import('../views/PlanoConta2FormView.vue'),
      },
      {
        path: ':id',
        name: 'planoConta2-editar',
        meta: {
          title: 'Plano de conta 2 - editar',
        },
        component: () => import('../views/PlanoConta2FormView.vue'),
      },
    ],
  },
];
