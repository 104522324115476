export default [
  {
    path: '/osSituacao/',
    name: 'osSituacao',
    component: () => import('../views/OsSituacaoView.vue'),
    redirect: { name: 'osSituacao-listar' },
    children: [
      {
        path: 'welcome',
        name: 'osSituacao-listar',
        component: () => import('../views/OsSituacaoWelcomeView.vue'),
        meta: {
          title: 'Situação de OS',
        },
      },
      {
        path: 'add',
        name: 'osSituacao-cadastrar',
        component: () => import('../views/OsSituacaoFormView.vue'),
        meta: {
          title: 'Cadastrar situação de OS',
        },
      },
      {
        path: ':id',
        name: 'osSituacao-editar',
        meta: {
          title: 'Editar situação de OS',
        },
        component: () => import('../views/OsSituacaoFormView.vue'),
      },
    ],
  },
];
