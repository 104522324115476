export default [
  {
    path: '/dfe/',
    name: 'dfe',
    title: 'Dfe',
    component: () => import('../views/DfeView.vue'),
    redirect: { name: 'exportXml' },
    children: [
      {
        path: 'exportXml',
        name: 'exportXml',
        component: () => import('../views/ExportarXmlFormView.vue'),
        meta: {
          title: 'Exportar XML',
        },
      },
    ],
  },
];
