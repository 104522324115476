export default [{
  path: '/favoritoProduto/',
  name: 'favoritoProduto',
  title: 'FavoritoProduto',
  component: () => import('../views/FavoritoProdutoView.vue'),
  redirect: { name: 'favoritoProduto-listar' },
  children: [
    {
      path: 'add',
      name: 'favoritoProduto-cadastrar',
      component: () => import('../views/FavoritoProdutoFormView.vue'),
      meta: {
        title: 'Produto favorito',
      },
    },
  ],
}];
