export default [
  {
    path: '/permissaoGrupo/',
    name: 'permissaoGrupo',
    title: 'permissaoGrupo',
    component: () => import('../views/PermissaoGrupoView.vue'),
    redirect: { name: 'permissaoGrupo-listar' },
    children: [
      {
        path: 'welcome',
        name: 'permissaoGrupo-listar',
        component: () => import('../views/PermissaoGrupoWelcomeView.vue'),
        meta: {
          title: 'permissaoGrupo listar',
        },
      },
      {
        path: 'add',
        name: 'permissaoGrupo-cadastrar',
        meta: {
          title: 'permissaoGrupo cadastrar',
        },
        component: () => import('../views/PermissaoGrupoFormView.vue'),
      },
      {
        path: ':id',
        name: 'permissaoGrupo-editar',
        meta: {
          title: 'permissaoGrupo editar',
        },
        component: () => import('../views/PermissaoGrupoFormView.vue'),
      },
    ],
  },
];
