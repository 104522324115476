export default [
  {
    path: '/veiculo/',
    name: 'veiculo',
    title: 'Veiculo',
    component: () => import('../views/VeiculoView.vue'),
    redirect: { name: 'veiculo-listar' },
    children: [
      {
        path: 'welcome',
        name: 'veiculo-listar',
        component: () => import('../views/VeiculoWelcomeView.vue'),
        meta: {
          title: 'Veículo',
        },
      },
      {
        path: 'add',
        name: 'veiculo-cadastrar',
        component: () => import('../views/VeiculoFormView.vue'),
        meta: {
          title: 'Cadastrar veículo',
        },
      },
      {
        path: ':id',
        name: 'veiculo-editar',
        component: () => import('../views/VeiculoFormView.vue'),
        meta: {
          title: 'Editar veículo',
        },
      },
    ],
  },
];
