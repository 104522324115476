class EnumBase {
  constructor(valueEnum, descriptionEnum) {
    this.valueEnum = valueEnum;
    this.descriptionEnum = descriptionEnum;
  }
}

function enumOrderByDescription(enumerator) {
  const arrayEnumerator = Object.values(enumerator);
  return arrayEnumerator.sort((a, b) => (a.descriptionEnum > b.descriptionEnum ? 1 : -1));
}

export default { EnumBase, enumOrderByDescription };
