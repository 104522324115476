export default [
  {
    path: '/osTipo/',
    name: 'osTipo',
    component: () => import('../views/OsTipoView.vue'),
    redirect: { name: 'osTipo-listar' },
    children: [
      {
        path: 'welcome',
        name: 'osTipo-listar',
        component: () => import('../views/OsTipoWelcomeView.vue'),
        meta: {
          title: 'Tipo de OS',
        },
      },
      {
        path: 'add',
        name: 'osTipo-cadastrar',
        component: () => import('../views/OsTipoFormView.vue'),
        meta: {
          title: 'Cadastrar tipo de OS',
        },
      },
      {
        path: ':id',
        name: 'osTipo-editar',
        meta: {
          title: 'Editar tipo de OS',
        },
        component: () => import('../views/OsTipoFormView.vue'),
      },
    ],
  },
];
