import { library } from '@fortawesome/fontawesome-svg-core';
import { faBuilding, faBuildingUser, faHeart, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faPhone);
library.add(faUser);
library.add(faBuildingUser);
library.add(faBuilding);
library.add(faHeart);

export default FontAwesomeIcon;
