export default [
  {
    path: 'contaPagar/add',
    name: 'contaPagar-cadastrar',
    component: () => import('../views/ContaPagarFormView.vue'),
    meta: {
      title: 'Cadastrar contas a pagar',
    },
  },
  {
    path: 'contaPagar/welcome',
    name: 'contaPagar-listar',
    component: () => import('../views/ContaPagarWelcome.vue'),
    meta: {
      title: 'Buscar contas a pagar',
    },
  },
  {
    path: 'contaPagar/:id',
    name: 'contaPagar-editar',
    component: () => import('../views/ContaPagarFormView.vue'),
    meta: {
      title: 'Editar contas a pagar',
    },
  },
];
