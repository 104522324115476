export default [{
  path: '/subclasse/',
  name: 'subclasse',
  title: 'Subclasse',
  component: () => import('../views/SubclasseView.vue'),
  redirect: { name: 'subclasse-listar' },
  children: [
    {
      path: 'welcome',
      name: 'subclasse-listar',
      component: () => import('../views/SubclasseWelcomeView.vue'),
    },
    {
      path: 'add',
      name: 'subclasse-cadastrar',
      component: () => import('../views/SubclasseFormView.vue'),
      meta: {
        title: 'Cadastrar subclasse',
      },
    },
    {
      path: ':id',
      name: 'subclasse-editar',
      meta: {
        title: 'Editar subclasse',
      },
      component: () => import('../views/SubclasseFormView.vue'),
    },
  ],
}];
