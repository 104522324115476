<script>
import { markRaw, watch, ref } from 'vue';
import router from '@/router/index';

export default {
  name: 'AppLayout',
};
</script>
<script setup>

const defaultLayout = 'DefaultLayout';
const layout = ref();

async function changeRoute() {
  try {
    if (router.currentRoute.value.name) {
      const layoutName = router.currentRoute.value.meta.layout || defaultLayout;
      const component = await import(`@/layouts/${layoutName}.vue`);
      layout.value = markRaw(component?.default);
    }
  } catch (e) {
    layout.value = defaultLayout;
  }
}

watch(() => router.currentRoute.value, () => changeRoute(), { immediate: true });
</script>

<template>
  <component :is="layout">
    <slot />
  </component>
</template>
