export default [
  {
    path: '/caixa/',
    name: 'caixa',
    title: 'Caixa',
    component: () => import('../views/CaixaView.vue'),
    redirect: { name: 'caixa-abrir' },
    children: [
      {
        path: 'abrir',
        name: 'caixa-abrir',
        component: () => import('../views/AbrirCaixaView.vue'),
        meta: {
          title: 'Abrir caixa',
        },
      },
      {
        path: 'fechar',
        name: 'caixa-fechar',
        component: () => import('../views/FecharCaixaView.vue'),
        meta: {
          title: 'Fechar caixa',
        },
      },
      {
        path: 'entrada',
        name: 'caixa-entrada',
        component: () => import('../views/EntradaCaixaView.vue'),
        meta: {
          title: 'Entrada caixa',
        },
      },
      {
        path: 'retirada',
        name: 'caixa-retirada',
        component: () => import('../views/RetiradaCaixaView.vue'),
        meta: {
          title: 'Retirada caixa',
        },
      },
      {
        path: 'welcome',
        name: 'caixa-listar',
        component: () => import('../views/CaixaWelcomeView.vue'),
        meta: {
          title: 'Gerenciar caixa',
        },
      },
      {
        path: 'add',
        name: 'caixa-cadastrar',
        component: () => import('../views/CaixaFormView.vue'),
        meta: {
          title: 'Cadastrar caixa',
        },
      },
      {
        path: ':id',
        name: 'caixa-editar',
        component: () => import('../views/CaixaFormView.vue'),
        meta: {
          title: 'Editar caixa',
        },
      },
      {
        path: 'fechamentos',
        name: 'caixa-consultarFechamentos',
        component: () => import('../views/ConsultarCaixasFechamentoView.vue'),
        meta: {
          title: 'Consultar caixas',
        },
      },
    ],
  },
];
