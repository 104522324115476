export default [
  {
    path: '/classe/',
    name: 'classe',
    title: 'Classe',
    component: () => import('../views/ClasseView.vue'),
    redirect: { name: 'classe-listar' },
    children: [
      {
        path: 'welcome',
        name: 'classe-listar',
        component: () => import('../views/ClasseWelcomeView.vue'),
      },
      {
        path: 'add',
        name: 'classe-cadastrar',
        component: () => import('../views/ClasseFormView.vue'),
        meta: {
          title: 'Cadastrar Classe',
        },
      },
      {
        path: ':id',
        name: 'classe editar',
        meta: {
          title: 'Classe editar',
        },
        component: () => import('../views/ClasseFormView.vue'),
      },
    ],
  },
];
