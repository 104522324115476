import { createApp } from 'vue';
import VueCookies from 'vue-cookies';
import VueFuether from 'vue-feather';
import dayjs from 'dayjs';
import money from 'v-money3';
import { vMaska } from 'maska';
import VCalendar from 'v-calendar';
import App from './App.vue';
import AppLayout from './layouts/AppLayout.vue';
import router from './router';
import { FontAwesomeIcon } from './utils';

const app = createApp(App);
app.provide('dayJS', dayjs);

app
  .use(VueCookies)
  .use(router)
  .use(money)
  .use(VCalendar, {})
  .directive('maska', vMaska)
  .component(FontAwesomeIcon.name, FontAwesomeIcon)
  .component(AppLayout.name, AppLayout)
  .component(VueFuether.name, VueFuether)
  .mount('#app');
