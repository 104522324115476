export default [
  {
    path: '/icmsUfs/',
    name: 'icmsUfs',
    title: 'ICMSUfs',
    component: () => import('../views/IcmsUfsView.vue'),
    redirect: { name: 'icmsUfs-listar' },
    children: [
      {
        path: 'welcome',
        name: 'icmsUfs-listar',
        component: () => import('../views/IcmsUfsWelcome.vue'),
        meta: {
          title: 'ICMS',
        },
      },
      {
        path: 'add',
        name: 'icmsUfs-cadastrar',
        component: () => import('../views/IcmsUfsFormView.vue'),
        meta: {
          title: 'Cadastrar ICMS',
        },
      },
      {
        path: ':id',
        name: 'icmsUfs-editar',
        component: () => import('../views/IcmsUfsFormView.vue'),
        meta: {
          title: 'Editar ICMS',
        },
      },
    ],
  },
];
