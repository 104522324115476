export default [
  {
    path: '/tabelaPreco/',
    name: 'tabelaPreco',
    title: 'Tabela de preço',
    component: () => import('../views/TabelaPrecoView.vue'),
    redirect: { name: 'tabelaPreco-listar' },
    children: [
      {
        path: 'welcome',
        name: 'tabelaPreco-listar',
        component: () => import('../views/TabelaPrecoWelcomeView.vue'),
        meta: {
          title: 'Tabela de preço',
        },
      },
      {
        path: 'add',
        name: 'tabelaPreco-cadastrar',
        component: () => import('../views/TabelaPrecoFormView.vue'),
        meta: {
          title: 'Cadastrar tabela de preço',
        },
      },
      {
        path: ':id',
        name: 'tabelaPreco-editar',
        component: () => import('../views/TabelaPrecoFormView.vue'),
        meta: {
          title: 'Editar tabela de preço',
        },
      },
      {
        path: 'tabelaPadrao',
        name: 'tabelaPadrao',
        component: () => import('../views/TabelaPadraoFormView.vue'),
        meta: {
          title: 'Tabela padrão',
        },
      },
    ],
  },
];
