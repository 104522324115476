export default [
  {
    path: '/resumoVendas/',
    name: 'resumoVendas',
    title: 'resumoVendas',
    component: () => import('../view/ResumoVendasView.vue'),
    redirect: { name: 'resumoVendas-welcome' },
    children: [
      {
        path: 'resumoVendas-welcome',
        name: 'resumoVendas-welcome',
        component: () => import('../view/ResumoVendasWelcomeView.vue'),
        meta: {
          title: 'Resumo de vendas',
        },
      },
    ],
  },
];
